import React, { Component } from "react";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import classnames from "classnames";

import setAuthToken from "../../utils/setAuthToken";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      errors: ""
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    const { name, password } = this.state

    e.preventDefault();

    let errors = {}
    if (name.length === 0) {
      errors.name = "Name is required"
    }
    if (password.length === 0) {
      errors.password = "Password is required"
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
    } else {
      const user = {
        name: this.state.name,
        password: this.state.password
      };
  
      this.loginUser(user);  
    }
  }

  loginUser = userData => {

    axios
      .post("/api/users/login", userData)
      .then(res => {
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        this.props.updateLoginState()
      })
      .catch(err => {
        if (err.response && err.response.data) {
          // console.log(err.response.data)
          this.setState({
            errors: err.response.data
          })  
        }
      });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Log In</h1>
              <p className="lead text-center">
                Sign in to see everyone's lists
              </p>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.name
                    })}
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.password
                    })}
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <input
                  type="submit"
                  className="btn btn-success btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
