import React, { useEffect, useState, useCallback } from "react";
// import isEmpty from "../../validation/is-empty";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";

// import PresentItem from "../items/PresentItem";
import OwnItem from "../items/OwnItem";
import { Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";

function UserList({ currentUser }) {
  const location = useLocation()
  
  const [pageUserID, setPageUserID] = useState(getPageUserID());
  const [pageUser, setPageUser] = useState(null);
  const [notes, setNotes] = useState(null);
  const [presents, setPresents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [year, setYear] = useState(2024);

  // returns ID string from URL path 
  function getPageUserID() {
    let pathComps = window.location.pathname.split("/")
    let pageUserID = pathComps[pathComps.length-1]
    return pageUserID
  }

  // live updater for textField changes
  function handleChange(event) {
    setNotes(event.target.value)
  };

  const getUserPresents = useCallback((pageUserID, year) => {
      axios
      .get(`/api/presents/user/${pageUserID}/${year}`)
      .then(res => {
        setPresents(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log("err", err)
      })
    }, [])
    
  const getPageUser = useCallback(() => {
    let pathComps = window.location.pathname.split("/")
    let pageUserID = pathComps[pathComps.length-1]

    if (pageUserID && pageUserID !== "undefined" && pageUserID !== undefined ) {

      setPageUserID(pageUserID)      

      axios
      .get(`/api/users/user/${pageUserID}`)
      .then(pageUser => {

        setPageUser(pageUser.data)
        setNotes(pageUser.data.listNotes)

        getUserPresents(pageUser.data._id, 2024);
      })
      .catch(err => {
        // likely unauthorized
        window.location.replace("/");
      })

    }
  }, [getUserPresents])
  
  function updateYear(year) {
    let pathComps = window.location.pathname.split("/")
    let userID = pathComps[pathComps.length-1]

    // console.log("yrr", userID)
    setYear(year)
    getUserPresents(userID, year);
  }

  function updateListNotes(wasSaved) {
    if (wasSaved) {
      axios.put(`/api/users/current/list-notes`, { listNotes: notes }).then(user => {
        var updatedUser = pageUser
        updatedUser.listNotes = notes
  
        setNotes(user.data.listNotes)
        setPageUser(updatedUser)
      });

    } else {
      setNotes(pageUser.listNotes)
    }
    setIsEditingNotes(false)
  }

  function listNotes() {
    if (isEditingNotes) {
      return (
        <div>
          <Row>
            <Col className="col-10">
              <textarea name="notes" 
                        // style={{ width: "100%" }}
                        onChange={handleChange}
                        value={notes} />
            </Col>
            <Col className="col-1" >
              <Row>
              <button style={{ background: "none", border: "none", cursor: "pointer", outline: "none" }} 
                  onClick={ updateListNotes.bind(this, true) }>              
                <span
                  aria-label="search"
                  role="img"
                  text-align="right"
                  style={{ fontSize: "16px", padding: "3px" }}
                  className="ml-auto"
                  title="Save"
                >
                  ✅
                </span>
              </button>
              </Row>
              <Row>
              <button style={{ background: "none", border: "none", cursor: "pointer", outline: "none" }} 
                  onClick={ updateListNotes.bind(this, false) }>              
                <span
                  aria-label="search"
                  role="img"
                  text-align="right"
                  style={{ fontSize: "16px", padding: "3px" }}
                  className="ml-auto"
                  title="Cancel"
                >
                  ❌
                </span>
              </button>
              </Row>
            </Col>
          </Row>
        </div>
      )

    } else {
      let isCurrentUser = pageUser && currentUser._id === pageUser._id

      return (
        <div>
        { isCurrentUser ? 
        <div>
            <button className="list-notes" title="Update List Notes" style={{ background: "none", border: "none", cursor: "pointer", outline: "none", textAlign: "left", paddingLeft: "0px" }} 
            onClick={ () => setIsEditingNotes(!isEditingNotes) }
             >              
            <span
              aria-label="search"
              role="img"
              text-align="right"
              style={{ fontSize: "16px", paddingRight: "7px" }}
              className="ml-auto"
            >
              ✏️
            </span>
            <span style={{ color: "black" }}>{notes || !isCurrentUser ? notes : " Add general notes for your wish list"}</span>
          </button>
          </div>
          : null  
        }
      </div>
      )
    }
  }

  function updatePresent(newPresent) {
    if(newPresent.isDeleted) {
      setPresents(presents => presents.filter(present => 
        present._id !== newPresent._id
      ))

    } else {
      setPresents(presents => presents.map(present => 
        present._id === newPresent._id ? { ...newPresent } : present
      ));  
    }
  }

  useEffect(() => {
    getPageUser();
  }, [location, getPageUser]);    

  useEffect(() => {
    getUserPresents(pageUserID,year)
  }, [currentUser, getUserPresents, year, pageUserID]);    
  
  return (
      <div className="tom">
        { 
        isLoading ? (
          <p>Loading...</p>

        ) : ( 
        <div key={currentUser.count}>
          <Row>
            <Col >
              <h3 className="header">{`${pageUser ? pageUser.name : "Wish"}'s List`}</h3>
            </Col>
            <Col>
              <Dropdown title="Browse Past Years" isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
                <DropdownToggle caret className="dropdown-button button">     
                  { year }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => updateYear(2024)}>2024</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2023)}>2023</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2022)}>2022</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2021)}>2021</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2020)}>2020</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2019)}>2019</DropdownItem>
                  <DropdownItem onClick={() => updateYear(2018)}>2018</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
            
          { listNotes() }

          <hr />

          { presents.length > 0 ? presents.map(present => (
              <OwnItem
                key={`${present.item}${present._id}`}
                currentUser={currentUser}
                id={present._id}
                item={present.item}
                link={present.link}
                notes={present.notes}
                userWished={present.userWished}
                userGifted={present.userGifted}
                year={present.year}
                updatePresent={updatePresent}
              />
            )) : (
              <div className="stretch" />
            ) }
        </div>
        )
        }
      </div>
    )
}

  export default UserList;
