import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardTitle, Col, Row } from "reactstrap";

class ListItem extends Component {
  // state = {
  //   name: this.props.name,
  //   id: this.props._id,
  //   color: this.props.color,
  //   count: this.props.count
  // };

  render() {
    const { name, id, isSelf, count } = this.props;

    // console.log("name", id)
    if (!id) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );

    } else {
      let outlineColor = isSelf ? "warning" : "success"
      return (
        <div>
          <Link to={`/list/${id}`}>
            <Card className={`card border-${outlineColor}`}>
            <Row style={{ padding: "6px" }}>
              <Col className="col-8" >
                <CardTitle style={{ marginBottom: "0rem", color: "black", textDecoration: "none" }}>{name}'s List</CardTitle>
              </Col>
              { count ? (
                <Col className="col-4" style={{ display: "flex", justifyContent: "end", color: "#8a8a8a", fontWeight: "600" }}>
                  <span>{count} left</span>
                </Col>
              ) : null}
          </Row>

              {/* <span>{name}'s List</span> */}
              {/* <span>{count} left </span> */}
            </Card>
          </Link>
        </div>
      );
    }
  }
}
export default ListItem;
