import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import ListItem from "../items/ListItem";
import Login from "./Login";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysTillXmas: this.getTimeTillXmas(),
    };
  }

  componentDidMount() {
    const { currentUser, updateUsersState } = this.props;
    if (currentUser) {
      updateUsersState()
    }
  }

  getTimeTillXmas() {
    const xmas = new Date("Dec 25, 2024 8:00:00").getTime();
    const now = new Date().getTime();
    const timeTill = xmas - now;
    const days = Math.floor(timeTill / (1000 * 60 * 60 * 24));

    return days;
  }

  render() {
    const { currentUser, others, updateLoginState } = this.props;
    const { daysTillXmas } = this.state;

    return (
      <div>
        {currentUser ? (
          <div>
            <Row>
              <Col className="col-5 my-auto">
                <h3>Your List</h3>
              </Col>
              <Col className="col-7 text-right my-auto">
                {daysTillXmas > 0 ? (
                  <h6 className="mb-0">
                    <strong className="text-success">
                      {daysTillXmas}
                    </strong>{" "}
                    days until xmas
                  </h6>
                ) : (
                  <h6 className="mb-0">
                    <strong className="text-success">Merry</strong>
                    <strong className="text-danger"> Christmas</strong>
                    <strong className="text-success">!</strong>
                    <strong className="text-danger">!</strong>
                  </h6>
                )}
              </Col>
            </Row>

            <ListItem
              name={currentUser.name}
              id={currentUser._id}
              isSelf={true}
            />

            <hr />

            <h3>Family Lists </h3>
            {(others ? others : []).map((user) => {
              return (
                <ListItem
                  key={user._id}
                  name={user.name}
                  id={user._id}
                  count={user.count}
                  isSelf={false}
                  />
              );
            })}
          </div>
        ) : (
          <Login updateLoginState={updateLoginState.bind(this, {})} />
        )}
      </div>
    );
  }
}

export default Home;
