import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "reactstrap";
import AddModal from "../items/AddModal";

class AppNavbar extends Component {
  addModalButton() {
    const { currentUser, kids, updateUsersState } = this.props;

    if (currentUser) {
      return <Nav>
        <NavItem style={{ fontSize: "25px" }} title="Add Item" className={"button"}>
          <AddModal currentUser={currentUser} kids={kids} updateUsersState={updateUsersState} />
        </NavItem>
      </Nav>
    }
  }

  render() {
    return (
      <div>
        <Navbar className="mb-4" color="danger">
          <Link className="navbar-brand " to="/" style={{ color: "white" }}>
            <h5 className="my-2 medium">
              <span
                aria-label="search"
                role="img"
                text-align="right"
                style={{ fontSize: "24px", paddingRight: "8px" }}
                className="ml-auto"
              >
                🎄   
              </span>
              XMAS
              </h5>
          </Link>

          { this.addModalButton() }
        
        </Navbar>
      </div>
    );
  }
}

export default AppNavbar;
