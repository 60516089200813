import React, { Component } from "react";
import {
  Button,
  // Container,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import axios from "axios";

class RepeatModal extends Component {
  state = {
    modal: false,
    repeated: false
  };


  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      repeated: false
    });
  };

  refresh = () => {
    window.location.reload()
  }

repeatItem = e => {
    const {
        id,
        item,
        notes,
        link,
      } = this.props;
  
    const newPresent = {
      item,
      link,
      notes,
      sourcePresentID: id
    };

    axios
      .post("/api/presents/add", newPresent)
      .then(res => {
        this.setState({ repeated: true })
      })
      .catch(err => {
        console.log("err", err)
      })
  };

  render() {
    const { repeated } = this.state
    const {
      item,
      notes,
      year
    } = this.props;

    return (
      <div>
         <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          onClick={this.toggle}
          title="Add to 2024 list"
        >
           🔄
        </span>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Repeat on this year's list</ModalHeader>
          <ModalBody>
            <Card body outline className="mb-2 p-2">
              <CardTitle>{item}</CardTitle>
              <CardText>{notes}</CardText>
            </Card>
            <CardText>{`Originally on ${year} list`}</CardText>
            <hr />

        { repeated ? (
            <Row>
                <Col>
                    <Button color="danger" onClick={this.toggle} block>
                    Close
                    </Button>
                </Col>

                <Col>
                <Button color="dark" onClick={ this.refresh } block>
                    Go to 2024 List
                </Button>
                </Col>
          </Row>

        ) : (
            <Row>
              <Col>
                <Button color="danger" onClick={this.toggle} block>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button color="success" onClick={this.repeatItem} block>
                  Copy to 2024
                </Button>
              </Col>

              {/* <Col>{submitButton}</Col> */}
            </Row>

        )}

          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default RepeatModal;
