import React, { Component } from "react";
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Dropdown, 
  DropdownMenu, 
  DropdownItem, 
  DropdownToggle } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import classnames from "classnames";

class AddModal extends Component {
  state = {
    item: "", 
    link: "", 
    notes: "",
    errors: "",
    addForUser: null,
    addedPresent: ""
  };

  toggle() {
    const { kids, currentUser } = this.props;
    let { modal, item, link, notes, addForUser } = this.state

    // get page user ID
    let pathComps = window.location.pathname.split("/")
    let pageUserID = pathComps[pathComps.length-1]

    // use kid if found with pageID, else 
    let newIsShowing = !modal
    let foundKid = kids.find(user => user._id === pageUserID)
    let newAddForUser = newIsShowing ? foundKid ?  foundKid : currentUser : addForUser

    // console.log("newAddForUser", foundKid, newAddForUser)

    // reset values to start form fresh
    this.setState({
      modal: newIsShowing,
      item: newIsShowing ? "" : item,
      link: newIsShowing ? "" : link,
      notes: newIsShowing ? "" : notes,
      addedPresent: "",
      addForUser: newAddForUser,
      errors: ""
    });

    if (newIsShowing) {
      setTimeout(function() {
        const input = document.getElementById("text-box");
        input.focus();
      }, 10);  
    }
    
  };

  // show / hide menu to select the user to which the gift will be given 
  toggleDropdown() {
    let { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  // change the value for the user to which the gift will be given
  updateAddForUser(user) {
    this.setState({ addForUser: user,
                    addedPresent: ""
                  })
  }
  
  onChange = event => {
    this.setState({ 
      [event.target.name]: event.target.value,
      addedPresent: "",
    });
  };

  // save values as new gift
  onSubmit = e => {
    let { currentUser, updateUsersState } = this.props
    const { item, link, notes, addForUser } = this.state;

    e.preventDefault();

    if (item.length === 0) {
      this.setState({
        errors: {item: "Item is required"}
      })
      return
    }

    const newPresent = {
      item,
      link,
      notes,
      id: addForUser && addForUser._id !== currentUser._id ? addForUser._id : null
    };

    axios
      .post("/api/presents/add", newPresent)
      .then(res => {
        const present = res.data.item;
        this.setState({
          item: "",
          link: "",
          notes: "",
          errors: "",
          addedPresent: present
        });

        // send message to App to pages shown behind AddModal
        updateUsersState()
      })
      .catch(err => {
        console.log("err", err, err.response.data)
        if (err.response && err.response.data) {
          this.setState({
            errors: err.response.data
          })
        }
      });
  };

  // render method - dropdown menu view to select the user to which the gift will be given
  kidsDropdown() {
    // const kids = JSON.parse(sessionStorage.getItem("kids"))
    const { dropdownOpen, addForUser } = this.state;
    const { kids, currentUser } = this.props;

    if (kids && kids.length > 0) {
      return (
      <Row className="m-auto">
        {/* <span style={{ paddingRight: "10px" }}>For</span> */}
        <Dropdown className="justify-content-start" style={{ paddingBottom: "15px" }} title="Browse Past Years" isOpen={dropdownOpen} toggle={this.toggleDropdown.bind(this)}>
            <DropdownToggle caret >     
              { `For ${addForUser ? addForUser.name : currentUser.name }` }
            </DropdownToggle>

            <DropdownMenu>
              { this.kidsDropdownValues(kids) }
            </DropdownMenu>
          </Dropdown>
      </Row>
      )
    }
  }

  // render method - dropdown values
  kidsDropdownValues(kids) {
    let { currentUser } = this.props
    let { addForUser } = this.state

    let kidViews = []

    // add self value
    if (currentUser && addForUser && addForUser._id !== currentUser._id) {
      kidViews.push(<DropdownItem key={currentUser._id} onClick={this.updateAddForUser.bind(this, currentUser)}>{currentUser.name}</DropdownItem>)  
    }

    // add values for all kids
    for (let i = 0; i < kids.length; i++) {
      let kid = kids[i]
      if (kid && (addForUser === null || (addForUser && addForUser._id !== kid._id))) {
        kidViews.push(<DropdownItem key={kid._id} style={{ cursor: "pointer" }} onClick={this.updateAddForUser.bind(this, kid)}>{kid.name}</DropdownItem>)  
      }
    }   
    
    return kidViews
  }

  render() {
    let { currentUser } = this.props
    const {
      item,
      link,
      notes,
      errors,
      modal,
      addForUser,
      addedPresent
    } = this.state;

    // console.log("page", currentUser._id, addForUser, addForUser ? addForUser._id : null)

    return (
      <div>
        <span
          // aria-label="search"
          // role="img"
          // text-align="right"
          style={{ fontSize: "45px", cursor: "pointer", color: "white" }}
          className="ml-auto nav"
          onClick={this.toggle.bind(this)}
          title={`Add Item`}
        >
          +
        </span>

        <Modal isOpen={modal} toggle={this.toggle.bind(this)}>
          <ModalHeader toggle={this.toggle.bind(this)}>{`Add to${currentUser && currentUser.kids && currentUser.kids.length > 0 ? "" : " Your"}  Wishlist`}</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>

              { this.kidsDropdown() }

            <div className="form-group">
                <input
                id="text-box"
                type="text"
                className={classnames("form-control form-control-lg", {
                    "is-invalid": errors.item
                })}
                placeholder="Item (*required)"
                name="item"
                value={item}
                onChange={this.onChange}
                />
                {errors.item && (
                <div className="invalid-feedback">{errors.item}</div>
                )}
            </div>
            <div className="form-group">
                <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Link (*should start with https)"
                name="link"
                value={link}
                onChange={this.onChange}
                />
            </div>
            <div className="form-group">
                <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Notes"
                name="notes"
                value={notes}
                onChange={this.onChange}
                />
            </div>

            { addedPresent ? (
                <div>
                <p className="lead text-center">
                    <strong>{addedPresent}</strong> {"has been added to "} 
                    <Link onClick={this.toggle.bind(this)} to={`/list/${currentUser._id === addForUser._id ? currentUser._id : addForUser._id}`}>{addForUser == null || currentUser._id === addForUser._id ? "your" : `${addForUser.name}'s`} list</Link>
                </p>
                </div>
            ) : (
                <div />
            )}

            <input
                type="submit"
                className="btn btn-success btn-block mt-4 medium"
            />
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AddModal;
