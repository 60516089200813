import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";

class EditModal extends Component {
  state = {
    modal: false,
    id: this.props.id,
    item: this.props.item,
    link: this.props.link,
    notes: this.props.notes,
    userWished: this.props.userWished,
    updatedPresent: "",
    aboutToDelete: false,
    updateAction: ""
  };

  // addStory = story => {
  //   Axios.post("/api/stories", story).then(story => console.log(story));
  // };

  toggleDropdown() {
    let { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      aboutToDelete: false
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    const { updatePresent } = this.props

    event.preventDefault();

    const updatedPresent = {
      user: this.state.userWished,
      item: this.state.item,
      link: this.state.link,
      notes: this.state.notes
    };

    axios
      .put(`/api/presents/edit/${this.state.id}`, updatedPresent)
      .then(present => {

        this.setState({
          updatedPresent: present.data.item,
          updateAction: "updated"
        });

        updatePresent(present.data)
        // window.location.reload();
      })
      .catch(err => {
        console.log("err", err)
      })
  };

  handleDelete = event => {
    const { aboutToDelete } = this.state;
    const { updatePresent } = this.props

    event.preventDefault();

    if (!aboutToDelete) {
      this.setState({
        aboutToDelete: true
      });

    } else {
      axios
      .delete(`/api/presents/delete/${this.state.id}`)
      .then(present => {
        if (!present || !present.data) {
          return;
        }

        this.setState({
          updatedPresent: present.data.item,
          updateAction: "deleted",
          aboutToDelete: false
        });

        updatePresent(present.data)

        // window.location.reload();
      })
      .catch(err => {
        console.log("err", err)
      })
    }
  };

  render() {
    const {
      item,
      link,
      notes,
      updatedPresent,
      updateAction,
      aboutToDelete
    } = this.state;

    return (
      <div>
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          onClick={this.toggle}
          title={`Edit ${item}`}
        >
          ✏️
        </span>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit Gift</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleSubmit}>

              <FormGroup>
                <Label for="item">Item</Label>
                <Input
                  type="text"
                  name="item"
                  id="item"
                  value={item}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="link">Link</Label>
                <Input
                  type="text"
                  name="link"
                  id="link"
                  value={link}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="notes">Notes</Label>
                <Input
                  type="text"
                  name="notes"
                  id="notes"
                  value={notes}
                  onChange={this.handleChange}
                />
              </FormGroup>
              {updatedPresent ? (
                <div>
                  <p className="lead text-center">
                    <strong>{updatedPresent}</strong> has been {updateAction}.
                    <Link to={`/`}> Go Back</Link>
                  </p>
                </div>
              ) : (
                <div />
              )}
              <Container fluid>
                <Row>
                  <Col style={{ paddingLeft: "0", paddingRight: "2" }}>
                    <Button className="medium" onClick={this.handleDelete} title={`Delete ${item}`} color="danger" block>
                      {aboutToDelete ? "Tap again to delete" : "Delete"}
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: "2", paddingRight: "0" }}>
                    <Button className="medium" type="submit" title={`Update ${item}`} color="warning" block>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default EditModal;
