import React, { Component } from "react";
// import { Link } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import { Button } from "reactstrap";

class Footer extends Component {
  logout() {
    localStorage.clear();
    window.location.replace("/");
  }

  render() {
    const footerButton = !isEmpty(localStorage.getItem("jwtToken")) ? (
      <Button color="danger bold" onClick={this.logout} style={{ width: "30%" }}>
        Logout
      </Button>
    ) : (
      <span />
      // <Link to="/login">
      //   <Button color="danger" style={{ width: "30%" }}>
      //     Login
      //   </Button>
      // </Link>
    );

    return (
      <footer className="bg-dark text-white p-3 text-center">
        {footerButton}
      </footer>
    );
  }
}

export default Footer;
