import React, { Component } from "react";
import {
  Button,
  // Container,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import axios from "axios";
// import { Link } from "react-router-dom";

class GiftModal extends Component {
  state = {
    modal: false,
    id: this.props.id,
    item: this.props.item,
    notes: this.props.notes,
    user: this.props.user,
    userGifted: this.props.userGifted,
    submitted: false,
    submitMessage: "",
    color: "",
    year: this.props.year
  };

  toggle = () => {
    const { userGifted, user, year } = this.state;

    if ((userGifted && user._id !== userGifted) || year !== 2024) {
      return;
    }

    this.setState({
      modal: !this.state.modal,
      submitted: false
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  giftItem = () => {
    const { updatePresent } = this.props

    const gifter = {
      userGifted: this.state.user._id
    };

    axios
      .put(`/api/presents/gift/${this.state.id}`, gifter)
      .then(res => {
        this.setState({
          modal: false,
          userGifted: res.data.userGifted,
          submitted: true,
          submitMessage: `${res.data.item} has been gifted`
        });

        updatePresent(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })

  };

  ungiftItem = () => {
    const { updatePresent } = this.props

    axios
    .put(`/api/presents/gift/${this.state.id}`)
    .then(res => {
      this.setState({
        modal: false,
        userGifted: null,
        submitted: true,
        submitMessage: `${res.data.item} has been ungifted`
      })

      updatePresent(res.data)
  })
    .catch(err => {
      console.log("err", err)
    })
  };

  render() {
    const {
      item,
      notes,
      userGifted,
      user,
      submitMessage,
      submitted,
      year
    } = this.state;

    const giftIcon =
      userGifted && userGifted === user._id ? (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          title={`Ungift ${item}`}
          onClick={this.toggle}
        >
          ⭐️
        </span>
      ) : userGifted ? (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "default" }}
          className="ml-auto"
          title={`Already Gifted`}
          onClick={this.toggle}
        >
          🔒
        </span>
      ) : year !== 2024 ? (
        <span
        aria-label="search"
        role="img"
        text-align="right"
        style={{ fontSize: "30px", cursor: "default" }}
        className="ml-auto"
        title={`Old List - Never Gifted`}
        onClick={this.toggle}
      >
        ⌛️
      </span>
      ) : (
        <span
          aria-label="search"
          role="img"
          text-align="right"
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="ml-auto"
          title={`Gift Item`}
          onClick={this.toggle}
        >
          🎁
        </span>
      )

    const outlineColor = userGifted ? "warning" : "success";

    const submitButton = !userGifted ? (
      <Button className="medium" color="success" onClick={this.giftItem} block>
        Gift Item
      </Button>
    ) : (
      <Button className="medium" color="danger" onClick={this.ungiftItem} block>
        Ungift Item
      </Button>
    );

    const submitFeedback = submitted ? (
      <p className="lead text-center">{submitMessage}</p>
    ) : (
      <div />
    );

    return (
      <div>
        {giftIcon}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Gift</ModalHeader>
          <ModalBody>
            <Card body outline color={outlineColor} className="card">
              <CardTitle>{item}</CardTitle>
              <CardText>{notes}</CardText>
            </Card>
            <hr />
            {submitFeedback}

            <Row>
              <Col>
                <Button className="medium" color="dark" onClick={this.toggle} block>
                  Close
                </Button>
              </Col>
              <Col >{submitButton}</Col>
            </Row>

            {/* <FormGroup>
                <Label for="ther">Someone else is gifting this item</Label>
                <Input
                  type="radio"
                  name="gift"
                  id="gift"
                  onChange={this.handleChange}
                />
              </FormGroup> */}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default GiftModal;
