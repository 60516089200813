import React, { Component } from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import isEmpty from "../../validation/is-empty";

import EditModal from "./EditModal";
import RepeatModal from "./RepeatModal";
import GiftModal from "./GiftModal";

export default class OwnItem extends Component {
  render() {
    const { item, link, notes, id, userWished, userGifted, currentUser, year, updatePresent } = this.props;

    const isCurrentYear = year === 2024
    
    const outlineColor =
    userWished === currentUser._id ? "warning" :
    userGifted && userGifted === currentUser._id
      ? "warning"
      : userGifted
      ? "danger"
      : year === 2024
      ? "success"
      : "secondary" ;

      let showGiftButton = userWished !== currentUser._id
      let showEditButtons = userWished === currentUser._id || (currentUser.kids && currentUser.kids.includes(userWished))

    const linkIcon = !isEmpty(link) ? (
      <a  href={link} 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="search"
          role="img"
          style={{ textDecoration: "none" }}
          title={link} >
          🔗
      </a>
    ) : (
      <span
        aria-label="search"
        role="img"
        title="No Link"
        target="_blank"
      >
        ▪️
      </span>
    );

    return (
      <Card className={`card border-${outlineColor}`}>
        <CardBody className="p-2">
          <Row>
            {/* text */}
            <Col className={`col-${showEditButtons ? showGiftButton ? "6" : "8" : "8"}`}>
                <CardTitle className="mb-0">{item}</CardTitle>
                <CardText>
                  <small>{notes}</small>
                </CardText>
            </Col>

            {/* own - repeat */}
            {!isCurrentYear && showEditButtons ? (
              <Col className="col-2">
                <RepeatModal
                  id={id}
                  item={item}
                  notes={notes}
                  year={year}
                  link={link}
                />
              </Col>
              ) : (
                null
            )}

            {/* hyperlink */}
            <Col className="col-2 center-icon">{linkIcon}</Col>

            {/* own - edit */}
            { showEditButtons && isCurrentYear ?  (
                            <Col className="col-2 center-icon">
                            <EditModal
                              id={id}
                              item={item}
                              link={link}
                              notes={notes}
                              userWished={userWished}
                              updatePresent={updatePresent}
                            />
                          </Col>            
            ) : (
              null
            )}

            { userWished !== currentUser._id ? (
              <Col className="col-2 center-icon">
                <GiftModal
                  id={id}
                  item={item}
                  link={link}
                  notes={notes}
                  user={currentUser}
                  userGifted={userGifted}
                  year={year}
                  updatePresent={updatePresent}
                />
              </Col>  
            ) : (
              null
            )}

          </Row>
        </CardBody>
      </Card>
    );
  }
}
