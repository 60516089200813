import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "./App.css";

import setAuthToken from "./utils/setAuthToken";
import AppNavbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
// import Register from "./components/auth/Register";
// import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import UserList from "./components/pages/UserList";
// import Add from "./components/pages/Add";
import axios from "axios";

var decoded = "";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  decoded = jwt_decode(localStorage.jwtToken);

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.clear();
    // clearCurrentProfile();
    window.location.href = "/login";
  }
}

class App extends Component {
  state = {
    currentUser: decoded,
    kids: [],
    otherUsers: []
  };

  componentDidMount() {
    this.setState({ currentUser: decoded });

    if (decoded) {
      this.setOtherUsers(decoded)
    }
  }

  updateLoginState() {
    let { currentUser } = this.state

    if (currentUser) {
      // log out
      this.setState({ currentUser: null });

    } else if (localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      decoded = jwt_decode(localStorage.jwtToken);
    
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        sessionStorage.clear();
        localStorage.clear();
        this.setState({ currentUser: null });

      } else {
        if (decoded) {
          this.setOtherUsers(decoded)
        }    
      }
    }
  }

  setOtherUsers(decoded) {
    axios
      .get("/api/users/all")
      .then(res => {
        // separate others for UI
        const otherUsers = res.data.filter(
          user => user._id !== decoded.id
        );

        // add kids names to storage 
        let foundSelf = res.data.find(user => user._id === decoded.id)
        let kidsIDs = foundSelf && foundSelf.kids ? foundSelf.kids : []
        let kids = []

        for (let i = 0; i < kidsIDs.length; i++) {
          let kidID = kidsIDs[i]

          let foundKid = otherUsers.find(user => user._id === kidID)
          if (foundKid) {
            kids.push({ _id: kidID, name: foundKid.name })
          }
        }

        // console.log("ref A", foundSelf.count)

        this.setState({ currentUser: foundSelf, 
                        otherUsers: otherUsers,
                        kids
                      });
      })
      .catch(res => {
        localStorage.clear();
      });
  }

  updateUsersState() {
    this.setOtherUsers(decoded)
  }

  render() {
    const  { currentUser, kids, otherUsers } = this.state;

    // console.log("count", currentUser.count)
    
    return (
      <BrowserRouter>
        <div className="page">

          <AppNavbar currentUser={currentUser} kids={kids} updateUsersState={ this.updateUsersState.bind(this) } />
          
          {/* <div className="container"> */}
              <div className="container content">
                <Routes>
                  <Route  exact 
                          path="/list/:id" 
                          element={<UserList currentUser={currentUser} />} />
                          
                  <Route 
                  path="/*" 
                  element={<Home  currentUser={currentUser} 
                                  others={otherUsers} 
                                  updateLoginState={ this.updateLoginState.bind(this) }
                                  updateUsersState={ this.updateUsersState.bind(this) }
                                  />}
                    />

                </Routes>
              </div>

              <Footer />
            </div>


          {/* </div> */}

      </BrowserRouter>
    );
  }
}

export default App;